import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import "./css/style.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import React, { useContext } from "react";
import { ThemeContext } from "./context/ThemeContext";
import AlertGlobal from "./jsx/components/AlertGlobal";

import { selectorPermission } from "./store/slices/userPermission";
import axiosClient from "./api/axiosClient";

const ThanhToan = lazy(() => import("./jsx/pages/ThanhToan"));
const App: React.FC = () => {
  const menuToggle = useContext(ThemeContext);
  const dataMenu = useSelector(selectorPermission);
  const { permission } = dataMenu;

  const [mainTitle, setMainTitle] = useState("");
  const [, setTitleIndex] = useState("");
  const [, setTitleLogin] = useState("");
  const [, setTitleLoginSpan] = useState("");

  let path: any = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  let location = useLocation();

  useEffect(() => {
    var url = window.location.pathname.split("/")[1];
    if (
      url.search("dang-ky-khach-hang-by-id") > -1 ||
      url.search("thu-phi-hang-rong-by-id") > -1
    ) {
      return;
    }
    if (
      window.location.pathname !== "/" &&
      window.location.pathname.search("/thanh-toan") &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/trang-ca-nhan" &&
      window.location.pathname !== "/doi-mat-khau" &&
      window.location.pathname.search("chi-tiet") < 0
    ) {
      if (!permission.includes(`/${url}`)) {
        alert("Bạn không có quyền truy cập vào chức năng này");
        window.history.back();
      }
    }
    axiosClient.defaults.headers['current-page'] = window.location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const fechMarketInfo = async () => {
      const response = await axiosClient.get("cau-hinh-cho");
      setMainTitle(response.data.main_title ?? "");
      setTitleIndex(response.data.title_index ?? "");
      setTitleLogin(response.data.title_login ?? "");
      setTitleLoginSpan(response.data.title_login_span ?? "");
      //console.log("mainTitle", mainTitle);
    };
    fechMarketInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    // <div className="vh-100">
    <div>
      <AlertGlobal />
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Switch>
          {/* {routesNoHeaderFooter} */}
          <Route path="/thanh-toan/:id" component={ThanhToan} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
